<template>
  <!--
    Ce fichier contient l'enveloppe de l'app vuetify.
    Ce n'est PAS ICI que tu viens ajouter ta fonction nulle,
    un stockage de variable globale ou je ne sais quelle merde.

    Pour avoir des variables globales, regarder dans `utils/storage.js`
    et utiliser this.$store.
    -->
  <v-app>
    <Navigation :pageTitle="routeName" />
    <v-main>
      <!-- MAIN ROUTER VIEW -->
      <v-container
        fluid
        class="overflow-y-auto fill-height d-block"
        id="main"
        v-scroll.self="onScroll"
      >
        <!-- Ces tags sont importants car ils permettent de détecter le scrolling -->
        <transition name="slide-fade">
          <router-view ref="content"></router-view>
        </transition>
      </v-container>
    </v-main>
    <!-- Return to top -->
    <v-fade-transition>
      <v-btn
        v-show="showReturnToTop"
        fab
        dark
        fixed
        bottom
        right
        color="secondary"
        @click="scrollToTop"
      >
        <v-icon>mdi-arrow-up-bold</v-icon>
      </v-btn>
    </v-fade-transition>
  </v-app>
</template>

<script>
import Navigation from "@/components/Navigation";
import { scrollToTop } from "@/utils/browser";

export default {
  name: "app",
  components: { Navigation },
  data() {
    return {
      showReturnToTop: false,
    };
  },
  computed: {
    routeName() {
      return this.$route.meta.name;
    },
    canScrollToTop() {
      return this.$route.meta.canScrollToTop;
    },
  },
  methods: {
    onScroll() {
      if (this.canScrollToTop) {
        this.showReturnToTop =
          this.$refs.content.$el.getBoundingClientRect().top < -300;
      } else {
        this.showReturnToTop = false;
      }
    },
    scrollToTop() {
      scrollToTop(this);
    },
  },
};
</script>

<style>
/* Scrolling behavior on bigfoot */
html,
body {
  height: 100%;
  overflow: hidden; /* Prevent out of page scrolling (better for UIs with vertical bars: eg. outlook.com) */
  background-color: rgb(39, 39, 39); /* Out of page (scrolling) color */
}
.v-main {
  background-color: var(--v-appback-base);
}
.v-application,
.v-main {
  height: 100%;
}
/* Hide scrollbar */
body::-webkit-scrollbar,
#main::-webkit-scrollbar,
.v-navigation-drawer__content::-webkit-scrollbar {
  display: none; /* Webkit */
}
body,
#main,
.v-navigation-drawer__content {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
/* Transitions between router views */
.slide-fade-enter-active {
  transition: all 0.1s ease 0.2s;
}
.slide-fade-leave-active {
  transition: all 0.2s ease;
}
.slide-fade-enter,
.slide-fade-leave-to {
  opacity: 0;
}
</style>
