import Vue from 'vue';
import Vuex from 'vuex';

import { resolveThumbnail } from "@/utils/format";

import axios from 'axios';

import Fuse from 'fuse.js'
import localforage from 'localforage';

Vue.use(Vuex)

localforage.config({
    name: 'Cache bigfoot'
});

/*
 * This class implements a storage that is super volatile (will update in Vue on change),
 * but it still restored during the session using sessionStorage.
 */

const CACHE_TIMEOUT = 15 * 60 * 1000;

function buildGroupsFuse(groups) {
    if (!groups) return;
    return new Fuse(groups, {
        keys: [
            "name",
            "TMdB.name",
            "TMdB.original_name",
            "WikiData.label"
        ],
        threshold: 0.4
    });
}

const globalStore = new Vuex.Store({
    state: {
        // User data: get init from session
        user: {
            name: undefined,
            username: undefined,
            avatar: undefined,
            restricted: true
        },
        darkMode: false,
        // Cache
        cache_timestamp: undefined,
        groups_fuse: undefined,
        groups: [],
        // Scripts
        loaded_youtube: false,
        // Banner
        lastBannerTime: undefined
    },
    mutations: {
        _set(state, payload) {
            Vue.set(state, payload.key, payload.val)
        },
        resetUser(state) {
            state.user = {
                name: "",
                username: "",
                avatar: "",
                restricted: true
            }
            localforage.removeItem("user");
        },
    },
    getters: {
        authenticated: (state) => {
            return Boolean(state.user) && Boolean(state.user.username);
        },
        getName: (state, getters) => {
            return getters.authenticated && state.user.name;
        },
        getAvatarLink: (state, getters) => {
            return getters.authenticated && resolveThumbnail(state.user.avatar);
        },
        getUserName: (state, getters) => {
            return getters.authenticated && state.user.username;
        },
        getUserProfileLink: (_, getters) => {
            return "/user/" + getters.getUserName;
        },
        restricted: (state, getters) => {
            return getters.authenticated && state.user.restricted;
        },
    },
    actions: {
        set(context, payload) {
            context.commit('_set', payload);
            if (payload.store || payload.store == undefined)
                return localforage.setItem(payload.key, JSON.stringify(payload.val));
            return Promise.resolve();
        },
        initialization(context) {
            // This function is called BEFORE Vue is even loaded. (main.js)
            // DO NOT CLUTTER !
            let promises = [];
            for (let key of ["user", "lastBannerTime", "cache_timestamp"]) {
                promises.push(localforage.getItem(key).then(val => context.dispatch('set', { key: key, val: JSON.parse(val), store: false })));
            }
            promises.push(localforage.getItem("groups").then(async val => {
                let groups = JSON.parse(val);
                await context.dispatch('set', { key: "groups", val: groups, store: false });
                await context.dispatch('set', { key: "groups_fuse", val: buildGroupsFuse(groups), store: false });
            }));
            return Promise.all(promises).then(() => {
                return true;
            });
        },
        setUser(context, user) {
            // eslint-disable-next-line
			return new Promise(async (resolve, reject) => {
				try {
					await context.dispatch('set', { key: 'user', val: user });
					resolve();
				} catch (err) {
					reject(err);
				}
			});
		}
    },
});

function checkCache() {
    if (!globalStore.state.cache_timestamp || (Date.now() - globalStore.state.cache_timestamp) > CACHE_TIMEOUT) {
        return axios.post(process.env.VUE_APP_API_URL + "/api/cache").then(async res => {
            let cache = res.data;
            await globalStore.dispatch('set', { key: 'cache_timestamp', val: Date.now() });
            await globalStore.dispatch('set', { key: 'groups', val: cache.groups });
            await globalStore.dispatch('set', { key: 'groups_fuse', val: buildGroupsFuse(cache.groups), store: false });
            return Promise.resolve();
        }).catch((err) => {
            clearCache();
            if (err.response && err.response.status == 401) {
                throw "/login";
            }
        });
    } else {
        return Promise.resolve();
    }
}

function getGroupsFuse() {
    return globalStore.state.groups_fuse;
}
function getGroups() {
    return globalStore.state.groups;
}
function setInitData(data) {
    return setUser(data.user);
}
function setUser(user) {
    return globalStore.dispatch('setUser', user);
}
function clearLogin() {
    globalStore.commit('resetUser');
}
function clearCache() {
    // We just mark the cache as outdated
    return globalStore.dispatch('set', { key: 'cache_timestamp', val: 0 });
}
function checkBannerState(banner) {
    let lastBannerTime = globalStore.state.lastBannerTime;
    return (!lastBannerTime || lastBannerTime < banner.time);
}
function setBannerState(banner) {
    return globalStore.dispatch('set', { key: "lastBannerTime", val: banner.time });
}
function setDarkMode(enabled) {
    return globalStore.dispatch('set', { key: "darkMode", val: enabled });
}
function storedDarkMode() {
    return localforage.getItem("darkMode").then(val => {
        if (val == undefined) return;
        globalStore.commit('_set', { key: 'darkMode', val: JSON.parse(val) });
        return globalStore.state.darkMode;
    });
}
function youtubeIsLoaded() {
    return globalStore.state.loaded_youtube;
}
function setYoutubeLoaded() {
    globalStore.commit('_set', { key: 'loaded_youtube', val: true });
}

export {
    globalStore,
    setInitData,
    setUser,
    getGroups,
    getGroupsFuse,
    checkCache,
    clearCache,
    clearLogin,
    checkBannerState,
    setBannerState,
    setDarkMode,
    storedDarkMode,
    youtubeIsLoaded,
    setYoutubeLoaded,
}