<template>
  <div>
    <!-- TOP BAR -->
    <v-app-bar color="topnav" dense dark app clipped-left>
      <v-app-bar-nav-icon
        @click="drawer = true"
        v-show="miniScreen"
      ></v-app-bar-nav-icon>

      <v-toolbar-title>{{ pageTitle }}</v-toolbar-title>

      <v-spacer></v-spacer>

      <!-- SEARCH -->
      <div v-if="authenticated">
        <SearchBar
          class="mx-auto pa-2"
          mode="searchbar"
          placeholder="Rechercher"
          background-color="topnav lighten-1"
          expandeable
        ></SearchBar>
      </div>
    </v-app-bar>

    <!-- MENU -->
    <v-navigation-drawer
      v-model="drawer"
      app
      clipped
      :temporary="miniScreen"
      :permanent="!miniScreen"
      :mini-variant="!miniScreen"
      :expandOnHover="!miniScreen"
      color="drawer"
      dense
    >
      <v-list dense flat class="py-0">
        <!-- Compte -->
        <v-list-item-group color="primary">
          <v-list-item
            class="px-2"
            v-if="authenticated"
            :to="getUserProfileLink"
          >
            <v-list-item-avatar>
              <v-img :src="getAvatarLink"></v-img>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>
                {{ getName }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ getUserName }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-else>
            <v-list-item-avatar>
              <v-icon class="justify-start"> mdi-account </v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>Non connecté</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-divider></v-divider>

          <!-- Lié à l'utilisateur -->

          <v-list-item
            v-for="item in usermenu()"
            :key="item.title"
            link
            exact
            :to="item.to"
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <template v-if="authenticated">
            <v-divider></v-divider>
            <!-- Menu principal -->
            <v-list-item
              v-for="item in mainmenu()"
              :key="item.title"
              link
              exact
              :to="item.to"
              @click="item.action && item.action()"
            >
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
          <v-divider></v-divider>

          <!-- Outils -->
          <v-list-item
            v-for="item in toolsmenu()"
            :key="item.title"
            link
            :to="item.to"
            @click="item.action && item.action()"
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import SearchBar from "@/components/Inputs/SearchBar";
import { setDarkMode, storedDarkMode } from "@/utils/storage";

export default {
  name: "navigation-menu",
  data() {
    return {
      // CONTROL
      drawer: false,
      expand: false,
    };
  },
  components: { SearchBar },
  props: {
    pageTitle: String,
  },
  methods: {
    // Récupérer user.group pour limiter l'affichage (channel invisible de l'administrastion)
    usermenu() {
      let menu = [{ title: "Page d'accueil", icon: "mdi-home", to: "/" }];
      if (this.authenticated) {
        menu.push({ title: "Historique", icon: "mdi-history", to: "/history" });
        if (!this.restricted) {
          menu.push({
            title: "Demandes d'upload",
            icon: "mdi-file-question",
            to: "/requests",
          });
        }
      } else {
        menu.push({ title: "Se connecter", icon: "mdi-login", to: "/login" });
      }
      return menu;
    },
    mainmenu() {
      let menu = [];
      if (!this.authenticated) return menu;
      if (!this.restricted) {
        menu.push(
          ...[
            {
              title: "Channels",
              icon: "mdi-television",
              to: "/search?type=channel",
            },
          ]
        );
      }
      menu.push(
        ...[
          { title: "Films", icon: "mdi-filmstrip", to: "/search?type=movie" },
          {
            title: "Séries",
            icon: "mdi-television-classic",
            to: "/search?type=tv",
          },
          {
            title: "Logiciels",
            icon: "mdi-content-save",
            to: "/search?type=software",
          },
          {
            title: "Jeux",
            icon: "mdi-gamepad-variant",
            to: "/search?type=game",
          },
        ]
      );
      if (this.expand) {
        if (!this.restricted) {
          menu.push({
            title: "Journaux",
            icon: "mdi-newspaper",
            to: "/search?type=newspaper",
          });
        }
        menu.push(
          ...[
            {
              title: "Autre documents",
              icon: "mdi-file-document",
              to: "/search?type=other",
            },
            {
              title: "Moins",
              icon: "mdi-chevron-up",
              action: () => {
                this.expand = false;
              },
            },
          ]
        );
      } else {
        menu.push({
          title: "Plus",
          icon: "mdi-chevron-down",
          action: () => {
            this.expand = true;
          },
        });
      }
      return menu;
    },
    toolsmenu() {
      let menu = [];
      if (this.$vuetify.theme.dark) {
        menu.push(
          ...[
            {
              title: "Désactiver le mode nuit",
              icon: "mdi-brightness-5",
              action: () => {
                setDarkMode(false);
                this.$vuetify.theme.dark = false;
              },
            },
          ]
        );
      } else {
        menu.push({
          title: "Mode nuit",
          icon: "mdi-brightness-6",
          action: () => {
            setDarkMode(true);
            this.$vuetify.theme.dark = true;
          },
        });
      }
      if (!this.authenticated) return menu;
      menu.push(
        ...[{ title: "Ajouter des fichier", icon: "mdi-upload", to: "/add" }]
      );
      menu.push(
        ...[
          {
            title: "À propos",
            icon: "mdi-information-outline",
            to: "/about",
          },
          { title: "Se déconnecter", icon: "mdi-logout", to: "/logout" },
        ]
      );
      return menu;
    },
  },
  mounted() {
    // Load dark mode from preferences
    storedDarkMode().then((darkMode) => {
      if (darkMode == undefined) return;
      this.$vuetify.theme.dark = darkMode;
    });
  },
  computed: {
    miniScreen() {
      return this.$vuetify.breakpoint.mdAndDown;
    },
    authenticated() {
      return this.$store.getters.authenticated;
    },
    restricted() {
      return this.$store.getters.restricted;
    },
    getAvatarLink() {
      return this.$store.getters.getAvatarLink;
    },
    getName() {
      return this.$store.getters.getName;
    },
    getUserName() {
      return this.$store.getters.getUserName;
    },
    getUserProfileLink() {
      return this.$store.getters.getUserProfileLink;
    },
  },
};
</script>

<style>
.nav-link {
  text-decoration: none;
}
</style>
