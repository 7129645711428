import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'mdi',
  },
  theme: {
    options: { customProperties: true },
    dark: false,
    themes: {
      light: {
        primary: "#1a79b2",
        accent: "#F78927",
        drawer: "#DEDEDE",
        topnav: "#142129",
        appback: "#F1F1F1",
        success: "#8BC623",
        info: "#19AEFF",
        warning: "#FF8B07",
        danger: "#FF413A",
        cards: "#FFFFFF"
      },
      dark: {
        primary: "#4EA5D9",
        accent: "#F78927",
        drawer: "#272727",
        topnav: "#142129",
        appback: "#0A1014",
        success: "#8BC623",
        info: "#19AEFF",
        warning: "#FF8B07",
        danger: "#FF413A",
        cards: "#22282B" //ou #1E1E1E ou #6C7072 ou #333333 ou #161C20
      }
    },
  },
});
