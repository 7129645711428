const axios = require('axios');

function searchTMDB(type, name) {
    return axios.post(process.env.VUE_APP_API_URL + "/api/searchTMDB", { type: type, name: name });
}

function getTMDBlink(type, id) {
    return "https://www.themoviedb.org/" + type + "/" + id;
}

function resolveTMDBImage(image, size = undefined) {
    // Supported sizes:
    //   "w92", "w154", "w185", "w342", "w500", "w780", "original"
    return "https://image.tmdb.org/t/p/w" + (size || "500") + image;
}

const TMDB_GENRES_MOVIE = { "genres": [{ "id": 16, "name": "Animation" }, { "id": 35, "name": "Comédie" }, { "id": 80, "name": "Crime" }, { "id": 99, "name": "Documentaire" }, { "id": 18, "name": "Drame" }, { "id": 10751, "name": "Familial" }, { "id": 9648, "name": "Mystère" }, { "id": 37, "name": "Western" }] };
const TMDB_GENRES_TV = { "genres": [{ "id": 16, "name": "Animation" }, { "id": 35, "name": "Comédie" }, { "id": 80, "name": "Crime" }, { "id": 99, "name": "Documentaire" }, { "id": 18, "name": "Drame" }, { "id": 10751, "name": "Familial" }, { "id": 36, "name": "Histoire" }, { "id": 10402, "name": "Musique" }, { "id": 9648, "name": "Mystère" }, { "id": 10749, "name": "Romance" }, { "id": 37, "name": "Western" }] };

export {
    searchTMDB,
    getTMDBlink,
    resolveTMDBImage,
    TMDB_GENRES_MOVIE,
    TMDB_GENRES_TV,
};
