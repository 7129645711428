import Vue from 'vue'
import VueRouter from 'vue-router'

import { detectLogin } from "@/utils/login";

// We use import() to load pages to support code splitting. See
// https://webpack.js.org/guides/code-splitting/#dynamic-imports

const HomePage = () => import('@/views/HomePage');
// PAGES
const About = () => import('@/views/About');
const Search = () => import('@/views/Search');
const ViewDocument = () => import('@/views/ViewDocument');
const ViewGroup = () => import('@/views/ViewGroup');
const ViewUser = () => import('@/views/ViewUser');
const AddDocument = () => import('@/views/AddDocument');
const Requests = () => import('@/views/Requests');

// LOGIN
const Logout = () => import('@/views/Logout');
const Login = () => import('@/views/Login');

// ADMIN
const Admin = () => import('@/views/Admin');
const AdminItemList = () => import('@/components/Admin/AdminItemList');
const Overview = () => import('@/components/Admin/Overview');
const EditUsergroups = () => import('@/components/Admin/EditUsergroups');
const WelcomeMessage = () => import('@/components/Admin/WelcomeMessage');

// ERRORS
const Error403 = () => import('@/views/ErrorPages/403');
const Error404 = () => import('@/views/ErrorPages/404');
const Error408 = () => import('@/views/ErrorPages/408');
const Error500 = () => import('@/views/ErrorPages/500');

Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    routes: [
        // HOME
        {
            path: '/',
            name: 'home',
            component: HomePage,
            meta: {
                name: "Accueil",
                canScrollToTop: true,
            }
        },
        // PAGES
        {
            path: "/about",
            name: "about",
            component: About,
            meta: {
                name: "À propos"
            }
        },
        {
            path: '/group/:id',
            name: "group",
            component: ViewGroup,
            meta: {
                name: "Groupe"
            }
        },
        {
            path: '/user/:id',
            name: "user",
            component: ViewUser,
            meta: {
                name: "Utilisateur"
            }
        },
        {
            path: '/doc/:id',
            name: "doc",
            component: ViewDocument,
            meta: {
                name: "Document"
            }
        },
        {
            path: '/add',
            name: "add",
            component: AddDocument,
            props: true,
            meta: {
                name: "Uploader des documents"
            }
        },
        {
            path: '/history',
            name: "history",
            component: Search,
            props: { history: true },
            meta: {
                name: "Historique",
                canScrollToTop: true,
            }
        },
        {
            path: '/search',
            name: "search",
            component: Search,
            meta: {
                name: "Rechercher",
                canScrollToTop: true,
            }
        },
        {
            path: '/requests',
            name: "requests",
            component: Requests,
            meta: {
                name: "Demandes d'upload"
            }
        },
        // ADMIN
        {
            path: '/admin',
            component: Admin,
            meta: {
                name: "Administration"
            },
            children: [
                {
                    path: '/',
                    component: Overview,
                    name: 'admin',
                },
                {
                    path: 'welcome',
                    component: WelcomeMessage,
                },
                {
                    path: 'userlist',
                    component: AdminItemList,
                    props: { type: "user" }
                },
                {
                    path: 'grouplist',
                    component: AdminItemList,
                    props: { type: "group" }
                },
                {
                    path: 'filelist',
                    component: AdminItemList,
                    props: { type: "file" }
                },
                {
                    path: 'usergroups',
                    component: EditUsergroups,
                },
            ],
        },
        // LOGIN
        {
            path: '/login',
            name: 'login',
            component: Login,
            props: true,
            beforeEnter: (to, from, next) => {
                if (to.query.logged_in) {
                    detectLogin().then(() => {
                        return next(to.query.next || '/');
                    }).catch(() => {
                        next();
                    });
                } else {
                    next();
                }
            }
        },
        {
            path: '/logout',
            name: 'logout',
            component: Logout,
        },
        // ERRORS
        {
            path: '/403',
            name: "403",
            component: Error403,
            meta: {
                name: "Error 403"
            }
        },
        {
            path: '/408',
            name: "408",
            component: Error408,
            meta: {
                name: "Error 408"
            }
        },
        {
            path: '/500',
            name: "500",
            component: Error500,
            meta: {
                name: "Error 500"
            }
        },
        {
            path: "*",
            name: "404",
            component: Error404,
            meta: {
                name: "Error 404"
            }
        }
    ],
});

export default router
