const tmdbapi = require('@/utils/tmdbapi');
import { isoToCountryCode, imageUrl } from 'flagpack-core';
import { ISO_639_TO_3166 } from './data';

const o_date_fr = new Intl.DateTimeFormat("fr", {
    dateStyle: "medium",
    timeStyle: "medium",
});
const o_date_short_fr = new Intl.DateTimeFormat("fr", {
    dateStyle: "short",
    timeStyle: "medium",
});
const o_date_veryshort_fr = new Intl.DateTimeFormat("fr", {
    dateStyle: "short",
});

function formatDate(date, short) {
    if (!date) {
        return "";
    }
    if (short == 2) return o_date_veryshort_fr.format(Date.parse(date));
    if (short) return o_date_short_fr.format(Date.parse(date));
    return o_date_fr.format(Date.parse(date));
}
function hasImage(group) {
    if (group.thumbnail) return true;
    if (['tv', 'movie'].includes(group.type)) {
        if (!group.TMdB) return false;
        return !!group.TMdB.poster;
    } else if (['software', 'game'].includes(group.type)) {
        if (!group.WikiData) return false;
        return !!group.WikiData.logo_image;
    }
    return false;
}
function resolveThumbnail(thumbnail) {
    return process.env.VUE_APP_API_URL + "/thumbs/" + thumbnail;
}
function resolveImage(group, size = undefined, season = undefined) {
    if (group.thumbnail) {
        return resolveThumbnail(group.thumbnail);
    } else if (['tv', 'movie'].includes(group.type)) {
        if (season && group.TMdB.seasons) {
            let selected = group.TMdB.seasons.filter(v => v.season_number == season);
            if (selected.length && selected[0].poster_path) {
                // Has season-specific image
                return tmdbapi.resolveTMDBImage(selected[0].poster_path, size);
            }
        }
        return tmdbapi.resolveTMDBImage(group.TMdB.poster, size);
    } else if (['software', 'game'].includes(group.type)) {
        return group.WikiData.logo_image;
    }
}
function getDownloadLink(doc, userjwt) {
    let result = process.env.VUE_APP_API_URL + "/dl/doc/" + doc._id.toString() + "?filename=" + encodeURIComponent(doc.filename);
    if (userjwt) result = result + "&key=" + encodeURIComponent(userjwt);
    return result;
}
function getDownloadLinkSeason(group, season, userjwt) {
    let result = process.env.VUE_APP_API_URL + "/dl/season/" + group._id.toString() + "?season=" + season;
    if (userjwt) result = result + "&key=" + encodeURIComponent(userjwt);
    return result;
}
function getReadableFileSizeString(fileSizeInBytes) {
    let i = -1;
    let byteUnits = [' kB', ' MB', ' GB', ' TB', 'PB', 'EB', 'ZB', 'YB'];
    do {
        fileSizeInBytes = fileSizeInBytes / 1024;
        i++;
    } while (fileSizeInBytes > 1024);

    return Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[i];
}
function getReadableDuration(secs) {
    let hours = Math.floor(secs / 3600);
    let minutes = Math.floor((secs - (hours * 3600)) / 60);
    let seconds = Math.floor(secs - (hours * 3600) - (minutes * 60));

    if (hours < 10) { hours = "0" + hours; }
    if (minutes < 10) { minutes = "0" + minutes; }
    if (seconds < 10) { seconds = "0" + seconds; }

    if (hours != 0) {
        if (seconds != 0) return hours + "h" + minutes + "m" + seconds + "s";
        if (minutes != 0) return hours + "h" + minutes;
        return hours + "h";
    }
    if (minutes != 0) return minutes + "m" + seconds + "s";
    return seconds + "s";
}
function counter(n) {
    return Array.from({ length: n }, (_, i) => i + 1);
}
function validFileMainname(fname) {
    return /^[a-z0-9À-ÿ_\s;,%.@()'[\]-]+$/i.test(fname);
}
function validUsergroupName(name) {
    return /^[a-z0-9]+$/.test(name);
}
function decomposeFilename(fname) {
    let fileext = "." + fname.split(".").pop();
    let filemainname = fname.substring(
        0,
        fname.length - fileext.length
    );
    return {
        fileext: fileext,
        filemainname: filemainname
    }
}
function formatVideoResolution(height) {
    if (height > 5000) {
        return ">4K";
    } else if (height > 4000) {
        return "4K";
    } else if (height > 2000) {
        return "2K";
    } else {
        return height + "p";
    }
}
function formatVideoMetadata(video, full) {
    if (!video) return "";
    return video.filter(v => v.codec_name != "mjpeg").map(
        v => formatVideoResolution(v.height) + (full ? (" (" + v.codec_name + ")") : "")
    ).join(", ");
}
function formatFlag(flg) {
    if (!flg) return "";
    let code = ISO_639_TO_3166[flg] || flg;
    try {
        return imageUrl(isoToCountryCode(code.toUpperCase()), 's');
    } catch {
        return "";
    }
}
function formatAudioMetadata(audio) {
    if (!audio) return [];
    return audio.map(v => { return { flg: formatFlag(v.language), info: v.language + ", " + v.channel_layout + ", " + v.codec_name } });
}
function formatSubsMetadata(subs) {
    if (!subs) return [];
    return subs.map(v => { return { flg: formatFlag(v.language), info: v.language + ", " + v.codec_name } });
}
function importMarked(safe = false) {
    return import("marked").then((marked) => {
        let markedOptions = {
            gfm: true,
            breaks: true,
        };
        if (safe) {
            const walkTokens = (token) => {
                if (token.type === "heading") {
                    // Disable headings
                    token.depth = 2;
                }
            };
            class CustomRenderer extends marked.Renderer {
                image() {
                    return "<span><i>&#x3C;Image suprim&#xE9;e&#x3E;</i></span>"; // Filter out images
                }
                table() {
                    return "<span><i>&#x3C;Tableau suprim&#xE9;&#x3E;</i></span>"; // Filter out tables
                }
            }
            markedOptions["walkTokens"] = walkTokens;
            markedOptions["renderer"] = new CustomRenderer();
        }
        return [marked, markedOptions];
    })
}
export {
    counter,
    hasImage,
    resolveImage,
    resolveThumbnail,
    formatDate,
    getDownloadLink,
    getDownloadLinkSeason,
    getReadableFileSizeString,
    getReadableDuration,
    importMarked,
    validFileMainname,
    decomposeFilename,
    formatAudioMetadata,
    formatVideoMetadata,
    formatSubsMetadata,
    validUsergroupName
};