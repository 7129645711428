/*
 * Wrapper around axios to handle classic errors (401, 403)
 */

import semver from 'semver';
import { clearLogin } from '../utils/storage';

let _axiosPost = (self, handlesAxiosError, ...names) => {
    return self.axios.post(...names).catch(err => {
        if (err.response) {
            if (err.response.status == 401) {
                clearLogin();
                return new Promise(resolve => self.$router.replace(
                    {
                        name: "login",
                        query: { err: err.response.data },
                        params: { to: self.$route.fullPath }
                    }, resolve
                ));
            } else if (err.response.status == 403) {
                return new Promise(resolve => self.$router.replace({ path: "/403" }, resolve));
            } else if (err.response.status == 404) {
                return new Promise(resolve => self.$router.replace({ path: "/404" }, resolve));
            }
        } else if (err.request && handlesAxiosError) {
            return new Promise(resolve => self.$router.push({ path: "/408" }, resolve));
        }
        throw err;
    });
}
function axiosPostPassAxios(...names) {
    return _axiosPost(this, false, ...names);
}
function axiosPost(...names) {
    return _axiosPost(this, true, ...names);

}

const AxiosWrappers = {
    install(Vue) {
        if (semver.lt(Vue.version, '3.0.0')) {
            Object.defineProperties(Vue.prototype, {
                axiosPost: {
                    get: function get() {
                        return axiosPost;
                    }
                },
                axiosPostPassAxios: {
                    get: function get() {
                        return axiosPostPassAxios;
                    }
                }
            });
        } else {
            Vue.config.globalProperties.axiosPost = axiosPost;
            Vue.config.globalProperties.axiosPostPassAxios = axiosPostPassAxios;
        }
        Vue.axiosPost = axiosPost;
        Vue.axiosPostPassAxios = axiosPostPassAxios;
    }
}

export default AxiosWrappers;