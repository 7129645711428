import { globalStore } from "@/utils/storage";

export function GROUPS() {
    let groups = [{ text: "Tous", value: null, media: false },];
    groups.push(...[
        { text: "Film", value: "movie", media: true },
        { text: "Série", value: "tv", media: true },
        { text: "Logiciel", value: "software", media: false },
        { text: "Jeu", value: "game", media: false },
    ]);
    if (!globalStore.getters.restricted) {
        groups.push(...[
            { text: "Channel", value: "channel", media: true },
            { text: "Journaux", value: "newspaper", media: true },
        ]);
    }
    groups.push({ text: "Autre document", value: "other", media: false });
    return groups;
}
export function sum(array) {
    return array.reduce((a, b) => a + b, 0);
}
export const ISO_639_TO_3166 = {
    "aar":"dj",
    "afr":"za",
    "aka":"gh",
    "alb":"al",
    "amh":"et",
    "arm":"am",
    "aze":"az",
    "bam":"ml",
    "bel":"by",
    "ben":"bd",
    "bis":"vu",
    "bos":"ba",
    "bul":"bg",
    "bur":"mm",
    "cat":"ad",
    "chi":"cn",
    "hrv":"hr",
    "cze":"cz",
    "dan":"dk",
    "div":"mv",
    "dut":"nl",
    "dzo":"bt",
    "eng":"gb-ukm",
    "est":"ee",
    "fij":"fj",
    "fil":"ph",
    "fin":"fi",
    "fre":"fr",
    "gaa":"gh",
    "geo":"ge",
    "ger":"de",
    "gre":"gr",
    "guj":"in",
    "hat":"ht",
    "heb":"il",
    "hin":"in",
    "hmo":"pg",
    "hun":"hu",
    "ice":"is",
    "ibo":"ng",
    "ind":"id",
    "gle":"ie",
    "ita":"it",
    "jpn":"jp",
    "kau":"ne",
    "kaz":"kz",
    "khm":"kh",
    "kmb":"ao",
    "kin":"rw",
    "kon":"cg",
    "kor":"kr",
    "kua":"ao",
    "kur":"iq",
    "kir":"kg",
    "lao":"la",
    "lat":"va",
    "lav":"lv",
    "lin":"cg",
    "lit":"lt",
    "lub":"cd",
    "ltz":"lu",
    "mac":"mk",
    "mlg":"mg",
    "may":"my",
    "mlt":"mt",
    "mao":"nz",
    "mah":"mh",
    "mon":"mn",
    "mos":"bf",
    "nep":"np",
    "nde":"zw",
    "nso":"za",
    "nor":"no",
    "nob":"no",
    "nno":"no",
    "nya":"mw",
    "pap":"aw",
    "pus":"af",
    "per":"ir",
    "pol":"pl",
    "por":"pt",
    "pan":"in",
    "que":"wh",
    "rum":"ro",
    "roh":"ch",
    "run":"bi",
    "rus":"ru",
    "sag":"cf",
    "srp":"rs",
    "srr":"sn",
    "sna":"zw",
    "sin":"lk",
    "slo":"sk",
    "slv":"si",
    "som":"so",
    "snk":"sn",
    "nbl":"za",
    "sot":"ls",
    "spa":"es",
    "ssw":"sz",
    "swe":"se",
    "tgl":"ph",
    "tgk":"tj",
    "tam":"lk",
    "tel":"in",
    "tet":"tl",
    "tha":"th",
    "tir":"er",
    "tpi":"pg",
    "tso":"za",
    "tsn":"bw",
    "tur":"tr",
    "tuk":"tm",
    "ukr":"ua",
    "umb":"ao",
    "urd":"pk",
    "uzb":"uz",
    "ven":"za",
    "vie":"vn",
    "wel":"gb-wls",
    "wol":"sn",
    "xho":"za",
    "zul":"za"
 }