/*
 * Login methods.
 */
import axios from 'axios';
import { setInitData, clearLogin } from '@/utils/storage';

const baseUrl = location.protocol + "//" + location.hostname + (location.port && ":" + location.port);

export function _authCheck() {
    return axios.get(process.env.VUE_APP_API_URL + '/checkLogin', { withCredentials: true })
        .then(response => {
            return setInitData(response.data);
        });
}

function performLogin(to, options, next) {
    /*
     * to: the return url
     * options.userinput: if provided, login and password to try manual login
     */
    if (options.userinput) {
        // Using post
        let query_url = process.env.VUE_APP_API_URL + '/login';
        if(options.white_list_token) query_url += '?wltkn=' + encodeURIComponent(options.white_list_token);
        axios.post(query_url, options.userinput).then((response) => {
            return setInitData(response.data).then(() => {
                next(to);
            });
        }).catch(err => {
            next(null, err.response && err.response.data || err);
        });
    } else {
        // Using redirection
        let res_url = baseUrl + '/login?logged_in=1';
        if (to && to != '/') {
            // The page to go to on successful login
            res_url += '&next=' + encodeURIComponent(to);
        }
        let redirect_url = process.env.VUE_APP_API_URL +
            '/login' +
            '?next=' +
            encodeURIComponent(res_url);
        if (options.white_list_token) {
            // If there is a whitelisting token
            redirect_url += '&wltkn=' + encodeURIComponent(options.white_list_token);
        }
        window.location.replace(redirect_url);
    }
}

export function loginCheck(to, options, next) {
    return _authCheck(to, next).then(() => {
        next(to);
    }).catch(error => {
        if (error.response) {
            if (error.response.status == 401) {
                clearLogin();
                performLogin(to, options, next);
            } else if (error.response.status == 403) {
                next('/403');
            } else {
                console.log(error);
            }
        } else {
            console.log(error);
            // Erreur de connexion
            next('/408');
        }
    });
}

export function detectLogin() {
    return _authCheck();
}