function copyToClipboard(textToCopy) {
    // navigator clipboard api needs a secure context (https)
    if (navigator.clipboard && window.isSecureContext) {
        // navigator clipboard api method'
        return navigator.clipboard.writeText(textToCopy);
    } else {
        // text area method
        let textArea = document.createElement("textarea");
        textArea.value = textToCopy;
        // make the textarea out of viewport
        textArea.style.position = "fixed";
        textArea.style.left = "-999999px";
        textArea.style.top = "-999999px";
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        return new Promise((res, rej) => {
            // here the magic happens
            let success = document.execCommand('copy');
            textArea.remove();
            if (success) res();
            else rej();
        });
    }
}

function scrollToTop(self) {
    self.$vuetify.goTo(0, { container: document.getElementById("main") });
}

export {
    copyToClipboard,
    scrollToTop
}