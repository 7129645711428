<template>
  <v-autocomplete
    v-model="selected_group"
    :items="search_results.length ? search_results : value ? [value] : []"
    :loading="isLoading"
    :label="label"
    :append-icon="isLoading ? 'mdi-loading mdi-spin' : ''"
    :append-outer-icon="searchBarMode ? 'mdi-magnify' : ''"
    :search-input.sync="search"
    :placeholder="placeholder"
    :required="required"
    :disabled="disabled"
    :rules="rules"
    :outlined="outlined"
    :background-color="backgroundColor"
    :color="color"
    @keydown.enter.prevent="enter"
    hide-details="auto"
    :clearable="clearable"
    :dense="dense"
    :item-text="text"
    hide-no-data
    autocomplete="false"
    no-filter
    return-object
    :class="getClass"
    :rounded="searchBarMode"
  >
  </v-autocomplete>
</template>

<script>
import VInput from "vuetify/lib/components/VInput/VInput.js";
import { checkCache, getGroupsFuse } from "@/utils/storage";

export default {
  name: "SearchBar",
  extends: VInput,
  data() {
    return {
      search: "",
      search_results: [],
      isLoading: false,
    };
  },
  props: {
    mode: {
      type: String,
      required: true,
    },
    required: Boolean,
    outlined: Boolean,
    disabled: Boolean,
    expandeable: Boolean,
    dense: Boolean,
    label: String,
    placeholder: String,
    clearable: Boolean,
    color: String,
    backgroundColor: String,
    value: Object,
    enteraction: Function,
    rules: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  methods: {
    getSearchResults(query) {
      this.search_results = ["Cache en cours de chargement..."];
      checkCache().then(() => {
        let results = getGroupsFuse()
          .search(query)
          .map((v) => v.item);
        if (!this.search) results = []; // If the search took too long
        this.search_results = results;
        this.isLoading = false;
      });
    },
    submit(val) {
      this.$emit("input", val);
    },
    redirectTo(val) {
      this.$router.push("/group/" + val._id);
    },
    enter() {
      if (this.mode == "searchbar") {
        if (this.search && this.search_results.length == 1) {
          this.redirectTo(this.search_results[0]);
        } else if (this.search) {
          this.$router.push("/search?q=" + encodeURIComponent(this.search));
          this.clear();
        }
      } else if (this.mode == "searchcrewbar") {
        this.enteraction();
      }
    },
    text(v) {
      let txt = v.name;
      if (v.TMdB && v.TMdB.release_date) {
        txt = txt + " (" + new Date(v.TMdB.release_date).getFullYear() + ")";
      }
      return txt;
    },
    clear() {
      this.search = "";
      this.$emit("hide", false);
      document.activeElement.blur();
    },
  },
  created: function () {
    // Une fonction "debounced" s'execute apres X ms sans avoir été appelée (pour éviter de spammer le serveur).
    // Ici 200ms
    this.debouncedGetSearchResults = this.lodash.debounce(
      this.getSearchResults,
      500
    );
  },
  watch: {
    search(v) {
      if (this.value && this.text(this.value) == v) return;
      if (!this.search) {
        this.isLoading = false;
        this.search_results = [];
        this.submit(null);
      } else {
        this.isLoading = true;
        this.debouncedGetSearchResults(this.search);
      }
    },
  },
  computed: {
    selected_group: {
      get() {
        return this.value;
      },
      set(val) {
        if (this.searchBarMode) {
          this.redirectTo(val);
          this.clear();
        }
        this.submit(val);
      },
    },
    searchBarMode() {
      return this.mode == "searchbar";
    },
    getClass() {
      if (this.expandeable) {
        return "expandable-input";
      } else {
        return "";
      }
    },
  },
};
</script>

<style scoped>
.expandable-input {
  width: 200px;
  transition: width 0.5s;
}
.expandable-input.v-input--is-focused {
  width: 300px;
  transition: width 0.5s;
}
</style>