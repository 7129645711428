import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import router from './routes/routes'

// Load libraries

// axios
import axios from 'axios'
import VueAxios from 'vue-axios'
axios.defaults.withCredentials = true
Vue.use(VueAxios, axios)

import AxiosWrappers from "./plugins/axiosWrappers"
Vue.use(AxiosWrappers);

// loadash
import VueLodash from 'vue-lodash'
import lodash from 'lodash'
Vue.use(VueLodash, { lodash: lodash })

// VueJS config
Vue.config.productionTip = false

// VUEX
import { globalStore } from "@/utils/storage";

globalStore.dispatch('initialization').then(() => {
  new Vue({
    vuetify,
    router,
    store: globalStore,
    render: h => h(App)
  }).$mount('#app')
});
